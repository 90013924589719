import React from 'react';
import './App.css';
import {Header} from './components/Header'
import {Footer} from './components/Footer'
import {ReaderActive, ReaderStatic} from "./components/Applications";
import {Snappbot} from "./components/Robot";

export const App = () => {

    return (
        <div className="App">
            <Header/>

            <section className="main-container" style={{flexGrow: 1}}>
                <div className="container">
                   <div className="main-content-box">
                       <div className="row">
                           <Snappbot/>
                           <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8">
                               <ReaderActive/>
                               <ReaderStatic/>
                           </div>
                       </div>
                   </div>
                </div>
            </section>

            <Footer/>
        </div>
    );
}

