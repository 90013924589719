import {useEffect} from "react";

export const Freshchat = () => {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'http://localhost:9000/freshchatwidget.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        }
    }, []);

    return null;
}
